(function (FroalaEditor, $) {
  FroalaEditor.DefineIcon('pageBreak', { NAME: 'horizontal-rule' });
  FroalaEditor.RegisterCommand('pageBreak', {
    title: 'Page Break',
    undo: true,
    focus: false,
    showOnMobile: true,
    refreshAfterCallback: false,
    callback: function () {
      if (!this.selection.inEditor()) {
        return;
      }
      this.html.insert('\uFEFF<div class="ty-fr-page-break fr-deletable">&nbsp;</div>\uFEFF');
    }
  });
})(FroalaEditor, jQuery);