(function ($, _, FroalaEditor) {
  'use strict';
  FroalaEditor.DEFAULTS = Object.assign(FroalaEditor.DEFAULTS, {});
  FroalaEditor.DefineIconTemplate('at_mention_button', '<i class="fas fa-at"></i>');
  FroalaEditor.DefineIcon('atMentionButton', { template: 'at_mention_button' });
  FroalaEditor.RegisterCommand('atMentionButton', {
    title: 'Mention Someone',
    focus: true,
    undo: true,
    callback: function (cmd, val, type) {
      var frElement = jQuery(this.$el), atWhoView = frElement.data('atwho').$el.find('.atwho-view');
      var ranges = this.selection.ranges();
      if (atWhoView[0].style.display !== 'block') {
        if (ranges && ranges.length && ranges[0].endContainer.nodeType === 3) {
          if (ranges[0].endContainer.data[ranges[0].endContainer.data.length - 1] !== '@') {
            this.html.insert(" @");
          }
          frElement.keyup();
        } else {
          if ($(ranges[0].endContainer).hasClass('atwho-query')) {
            frElement.keyup();
          } else {
            this.html.insert(" @");
            frElement.keyup();
          }
        }
      } else {
        frElement.atwho('hide');
      }
    }
  });

})(window.jQuery, window._, FroalaEditor);