(function (FroalaEditor, $) {
  FroalaEditor.DefineIcon('codeTag', { NAME: 'code' });
  FroalaEditor.RegisterCommand('codeTag', {
    title: 'Code tag',
    undo: true,
    focus: false,
    showOnMobile: true,
    refreshAfterCallback: false,
    callback: function () {
      if (!this.selection.inEditor()) {
        return;
      }
      var selection = this.selection.ranges()[0];
      var element = $(selection.startContainer).closest('code').get(0);
      if (element && selection.startContainer === selection.endContainer) {
        var lines = $(element).find('.code-line');
        for (var i = 0; i < lines.length; i++) {
          lines.get(i).outerHTML = lines.get(i).innerText;
        }
        element.outerHTML = element.innerHTML;
      } else {
        if (selection.endOffset - selection.startOffset !== 0) {
          var code = document.createElement("code");
          code.appendChild(selection.extractContents());
          selection.insertNode(code);
          var childNodes = $(code).get(0).childNodes;
          for (var i = 0; i < childNodes.length; i++) {
            if (childNodes[i].nodeType === 3) {
              $(childNodes[i]).replaceWith(function () {
                return '<span class="code-line">' + $(this).text() + '</span>';
              });
            } else if (childNodes[i].outerHTML === '<br>') {
              childNodes[i].parentNode.removeChild(childNodes[i]);
              i--;
            }
          }
        } else {
          this.html.insert('<code></code>');
          var newLine = $('<span>').addClass('code-line').html('<br>');
          $(this.selection.ranges()[0].endContainer).closest('code').append(newLine);
          this.selection.setAtStart(newLine.get(0));
          this.selection.restore();
        }
      }
    },
    refresh: function () {
      /**
       * @name enterKeyOnCode
       * @param {*} editor
       * @param {*} ranges
       * @param {*} focusedLine
       * @param {*} newLine
       * 
       * @description
       * event on keypress (enter) on code element
       */
      function enterKeyOnCode(editor, ranges, focusedLine, newLine) {
        var codeTagElement = $(ranges.startContainer).closest('code');
        var leftString = focusedLine.text().substr(0, ranges.startOffset).trim();
        var rightString = focusedLine.text().substr(ranges.endOffset, focusedLine.text().length - ranges.endOffset).trim();
        if (!leftString && !rightString) {
          if (codeTagElement.children().get(codeTagElement.children().length - 1) === focusedLine.get(0)) {
            codeTagElement.after('<br/>');
            editor.selection.setAfter(codeTagElement.get(0));
            var loc = codeTagElement.find('.code-line');
            for (var i = 0; i < loc.length; i++) {
              if (loc.get(i).innerText.trim()) {
                continue;
              }
              loc.get(i).parentNode.removeChild(loc.get(i));
            }
          } else {
            focusedLine.after(newLine.html('<br/>'));
          }
        } else {
          (!leftString && rightString) ? focusedLine.html('<br/>') : (leftString && !rightString) ? angular.noop() : focusedLine.text(leftString);
          focusedLine.after(newLine.html((!leftString && rightString) ? rightString : (leftString && !rightString) ? '<br/>' : rightString));
        }
        editor.selection.setAtStart(newLine.get(0));
        editor.selection.restore();
      }

      /**
       * @name backspaceKeyOnCode
       * @param {*} editor
       * @param {*} ranges
       * @param {*} focusedLine 
       * 
       * @description
       * event on keypress (backspace) on code element
       */
      function backspaceKeyOnCode(editor, ranges, focusedLine) {
        var codeTagElement = $(ranges.startContainer).closest('code');
        if (codeTagElement.text().trim()) {
          if (ranges.startOffset !== 0 || ranges.endOffset !== 0) {
            return;
          }
          var sibling = focusedLine.get(0).previousSibling;
          if (!sibling) {
            return;
          }
          var preText = sibling.innerText;
          sibling.parentNode.removeChild(sibling);
          editor.selection.setAtStart(focusedLine.get(0));
          editor.html.insert(preText);
        } else {
          codeTagElement.get(0).parentNode.removeChild(codeTagElement.get(0));
        }
      }

      this.events.on('keydown', function (e) {
        var ranges = this.selection.ranges()[0];
        if ($(ranges.startContainer).closest('code').get(0)) {
          if (e.keyCode == 13) {
            e.preventDefault();
            enterKeyOnCode(this, ranges, $(ranges.endContainer).closest('.code-line'), $('<span>').addClass('code-line'));
            return false;
          } else if (e.keyCode == 8) {
            backspaceKeyOnCode(this, ranges, $(ranges.endContainer).closest('.code-line'));
          } else {
            var startContainer = this.selection.ranges()[0].startContainer;
            if (startContainer.nodeName === 'CODE' &&  startContainer.firstChild && startContainer.firstChild.innerHTML === '<br>') {
              startContainer.firstChild.innerHTML = '';
              this.selection.setAtStart(startContainer.firstChild);
              this.selection.restore();
            }
          }
        }
      }, true);
    }
  });
})(FroalaEditor, jQuery);