(function (FroalaEditor, $) {

  FroalaEditor.DEFAULTS = $.extend(FroalaEditor.DEFAULTS, {
    upgradeModal: ''
  })
 
  // Define the plugin.
  FroalaEditor.PLUGINS.insertVariableStarter = function (editor) {
  
    // Public method that is visible in the instance scope.
    function openModal () {
      editor.opts.upgradeModal();
    }
    
    return {
      openModal: openModal
    };
  };
  
  FroalaEditor.DefineIconTemplate('starter_icon', '<text class="vertical-align dropdown-text-icon"><p class="icon-crown w-20 v-m m-b-0"></p><p class="_600 inline mb-0"><i class="fas fa-brackets-curly"></i></p></text>');
  FroalaEditor.DefineIcon('insertVariableDropdownStarter', {template: 'starter_icon'});
  FroalaEditor.RegisterCommand('insertVariableDropdownStarter', {
    title: 'Insert Variables',
    type: 'dropdown',
    callback: function (cmd, val) {
    },
    refresh: function ($btn, val) { 
    },
    refreshOnShow: function ($btn, $dropdown) {
      this.insertVariableStarter.openModal();
    },
    plugin: 'insertVariableStarter'
  });
})(FroalaEditor, jQuery);
