(function ($, _, FroalaEditor) {
  'use strict';

  FroalaEditor.DEFAULTS = Object.assign(FroalaEditor.DEFAULTS, {});
  FroalaEditor.DefineIconTemplate('icon_text_template', '<i class="fas fa-cut"></i>');
  FroalaEditor.DefineIcon('textTemplate', { template: 'icon_text_template' });
  FroalaEditor.PLUGINS.textTemplate = function (editor) {
    var state = { isSnippetOpen: false, isBlueprintOpen: false }, isDropdownOpen = false;
    var blueprintSearchBox = $('<input type="text" translate translate-attr-placeholder="tasks.primaryFilter.assignee.placeHolder.by_blueprint_name"/>');
    blueprintSearchBox
      .addClass('form-control')
      .addClass('blueprint-search-box');

    function _throttle(fn, wait) {
      var time = Date.now();
      return function () {
        if ((time + wait - Date.now()) < 0) {
          fn();
          time = Date.now();
        }
      };
    }

    function _setButtonBehave(editor) {
      var el = editor.$tb[0], snippetTopMenu = $(el).find('.expand-snippets'), snippetContent = $(el).find('.snippet-content'),
        blueprintTopMenu = $(el).find('.expand-blueprints'), blueprintContent = $(el).find('.blueprint-content');
      state.isSnippetOpen = (isDropdownOpen ? state.isSnippetOpen : false);
      state.isBlueprintOpen = (isDropdownOpen ? state.isBlueprintOpen : false);
      state.isSnippetOpen ? snippetContent.addClass('collapse') : snippetContent.removeClass('collapse');
      state.isBlueprintOpen ? blueprintContent.addClass('collapse') : blueprintContent.removeClass('collapse');
      state.isSnippetOpen ? snippetTopMenu.addClass('open') : snippetTopMenu.removeClass('open');
      state.isBlueprintOpen ? blueprintTopMenu.addClass('open') : blueprintTopMenu.removeClass('open');
      blueprintSearchBox.css('display', 'block').css('border-radius', '0');
    }

    function _initListener(editor) {
      state.isSnippetOpen = state.isBlueprintOpen = false, isDropdownOpen = false;
      var body = $(document.getElementsByTagName('body'));
      var el = editor.$tb[0],
        snippetTopMenu = $(el).find('.expand-snippets'),
        blueprintTopMenu = $(el).find('.expand-blueprints'),
        snippetMenuContainer = $(el).find('.snippet-menu-container'),
        blueprintMenuContainer = $(el).find('.blueprint-menu-container'),
        textTemplateButtonNav = $(el).find('[id^=textTemplate-]');

      function onBodyClick() {
        if (isDropdownOpen) {
          isDropdownOpen = false;
          _setButtonBehave(editor);
        }
      }
      body.on('click', onBodyClick);

      function onTextTemplateButtonNavClick(e) {
        e.stopImmediatePropagation();
        isDropdownOpen = !isDropdownOpen;
        _setButtonBehave(editor);
      }
      textTemplateButtonNav.on('click', onTextTemplateButtonNavClick);

      function onSnippetTopMenuClick(e) {
        e.stopImmediatePropagation();
        state.isSnippetOpen = !state.isSnippetOpen;
        state.isBlueprintOpen = false;
        _setButtonBehave(editor);
      }
      snippetTopMenu.on('click', onSnippetTopMenuClick);

      function onBlueprintTopMenuClick(e) {
        e.stopImmediatePropagation();
        state.isBlueprintOpen = !state.isBlueprintOpen;
        state.isSnippetOpen = false;
        _setButtonBehave(editor);
      }
      blueprintTopMenu.on('click', onBlueprintTopMenuClick);

      function onSnippetMenuContainerThrottle() {
        return _throttle(function () {
          if (!editor.opts.template.allTemplateLoaded) {
            if ((snippetMenuContainer[0].scrollHeight - snippetMenuContainer.scrollTop() < snippetMenuContainer.height() + 120) && !editor.opts.template.isLoading) {
              editor.opts.template.getNextTemplateData()
                .then(function (items) {
                  appendSnippetsData(items);
                });
            }
          }
        }, 10);
      }
      snippetMenuContainer.on('scroll', onSnippetMenuContainerThrottle());

      function onBlueprintMenuContainerThrottle() {
        return _throttle(function () {
          if (!editor.opts.template.allBlueprintsLoaded) {
            if ((blueprintMenuContainer[0].scrollHeight - blueprintMenuContainer.scrollTop() < blueprintMenuContainer.height() + 120) && !editor.opts.template.isBlueprintLoading) {
              editor.opts.template.getNextBlueprintData()
                .then(function (items) {
                  appendBlueprintsData(items);
                });
            }
          }
        }, 10);
      }
      blueprintMenuContainer.on('scroll', onBlueprintMenuContainerThrottle());

      function debounce(callback, delay) {
        var timeout;
        return function () {
          clearTimeout(timeout);
          timeout = setTimeout(callback, delay);
        };
      }

      function getBlueprintData() {
        var container = $('.blueprint-menu-container');
        if (!editor.opts.template.isBlueprintLoading) {
          container.html('<span class="d-block text-center p-2">loading...</span>');
        }
        editor.opts.template.apiBlueprintParams.page = 1;
        editor.opts.template.paginationBlueprintInfo = {};
        editor.opts.template.apiBlueprintParams.q = blueprintSearchBox.val();
        editor.opts.template.allBlueprintsLoaded = false;
        editor.opts.template.getNextBlueprintData().then(
          function (items) {
            container.html('');
            appendBlueprintsData(items);
          });
      }

      blueprintSearchBox.on('keyup', debounce(getBlueprintData, 350));

      $(editor.$el).bind('$destroy', function () {
        body.off('click', onBodyClick);
        textTemplateButtonNav.off('click', onTextTemplateButtonNavClick);
        snippetTopMenu.off('click', onSnippetTopMenuClick);
        blueprintTopMenu.off('click', onBlueprintTopMenuClick);
        snippetMenuContainer.off('scroll', onSnippetMenuContainerThrottle());
        blueprintMenuContainer.off('scroll', onBlueprintMenuContainerThrottle());
        blueprintSearchBox.off('keyup', debounce(getBlueprintData, 350));
      });
      _setButtonBehave(editor);
    }

    return {
      _init: function () {
        var container = editor.$tb.find('.fr-float-right');
        container.css('position', 'relative');
        blueprintSearchBox.bind('click keydown mousedown mouseup', function (e) {
          e.stopImmediatePropagation();
        });
        var blueprintMenuContainer = container.find('.blueprint-menu-container');
        blueprintSearchBox.insertBefore(blueprintMenuContainer);
        
        var snippetList = container.find('.snippet-menu-container');
        snippetList.empty();
        editor.opts.template.getNextTemplateData()
          .then(function (items) {
            appendSnippetsData(items);
          });
        editor.opts.template.getNextBlueprintData()
          .then(function (items) {
            appendBlueprintsData(items);
          });
        _initListener(editor);
      },
      appendTemplate: function (val) {
        var templateList = editor.opts.template.items;
        var data = templateList.find(function (value) {
          return value.id == val;
        });
        var snippetTemplate = '\uFEFF<span class="insert-snippet-tag fr-deletable" contenteditable="false" data-snippet-id="' + data.id + '">[[' + data.title + ']]</span>\uFEFF';
        editor.html.insert(snippetTemplate);
      },
      appendBlueprint: function (val) {
        var blueprintList = editor.opts.template.itemsBlueprint;
        var data = blueprintList.find(function (value) {
          return value.id == val;
        });
        var blueprintTemplate = '\uFEFF<span class="insert-blueprint-tag fr-deletable" contenteditable="false" data-blueprint-id="' + data.id + '">[[' + data.title + ']]</span>\uFEFF';
        editor.html.insert(blueprintTemplate);
      },
      createNewTemplate: function () {
        editor.opts.template.openCreateTextTemplateModal().then(function () {
          editor.textTemplate._init();
          _setButtonBehave(editor);
        });
      },
      setDropdownOpenState: function (isOpen) {
        isDropdownOpen = isOpen;
        _setButtonBehave(editor);
      },
      getDropdownOpenState: function () {
        return isDropdownOpen;
      },
      resetState: function () {
        state = { isSnippetOpen: false, isBlueprintOpen: false };
        isDropdownOpen = false;
      }
    };
  };

  function appendSnippetsData(data) {
    var container = $('.snippet-menu-container');
    if (data.length) {
      for (var i = 0; i < data.length; i++) {
        var liSnippetMenu = $('<li/>')
          .attr('role', 'presentation');
        var menuLink = $('<a/>');
        menuLink.addClass('fr-command')
          .attr('tabindex', '-1')
          .attr('role', 'option')
          .attr('data-cmd', 'textTemplate')
          .attr('data-param1', data[i].id)
          .attr('data-param2', 'snippet')
          .text(data[i].title);
        liSnippetMenu.append(menuLink);
        container.append(liSnippetMenu);
      }
    } else {
      var emptyLabel = $('<span/>')
        .text('No Data')
        .addClass('no-data');
      container.append(emptyLabel);
    }
  }

  function appendBlueprintsData(data) {
    var container = $('.blueprint-menu-container');
    if (data.length) {
      for (var i = 0; i < data.length; i++) {
        var liBlueprintMenu = $('<li/>')
          .attr('role', 'presentation');
        var menuLink = $('<a/>');
        menuLink.addClass('fr-command')
          .attr('tabindex', '-1')
          .attr('role', 'option')
          .attr('data-cmd', 'textTemplate')
          .attr('data-param1', data[i].id)
          .attr('data-param2', 'blueprint')
          .text(data[i].title);
        liBlueprintMenu.append(menuLink);
        container.append(liBlueprintMenu);
      }
    } else {
      var emptyLabel = $('<span/>')
        .text('No Data')
        .addClass('no-data');
      container.append(emptyLabel);
    }
  }

  function generateSnippetMenuList(editorOption) {
    var snippetContent = $('<div/>').addClass('snippet-content'),
      snippetMenuUl = $('<ul/>').addClass('snippet-menu-container'),
      createNewSnippetButton = $('<a/>')
        .addClass('fr-create-new-template')
        .addClass('fr-command')
        .addClass('_600')
        .attr('tabindex', '-1')
        .attr('role', 'option')
        .attr('data-cmd', 'textTemplate')
        .attr('data-param1', 'create-snippet')
        .text('+ ' + editorOption.template.createNewTemplateBtnText);

    snippetContent
      .append(createNewSnippetButton)
      .append(snippetMenuUl);

    return snippetContent;
  }

  function generateBlueprintMenuList() {
    var blueprintContent = $('<div/>')
      .addClass('blueprint-content');
    var blueprintMenuList = $('<ul/>')
      .addClass('blueprint-menu-container');
    blueprintContent.append(blueprintMenuList);
    return blueprintContent;
  }

  function _generateDropdownHtml(editorOption) {
    var ul = $('<ul/>')
      .addClass('fr-dropdown-list')
      .addClass('fr-dropdown-menu-list')
      .addClass('templates-dropdown')
      .addClass('role', 'presentation'),
      liSnippetParent = $('<li/>').attr('role', 'presentation'),
      liBlueprintParent = $('<li/>').attr('role', 'presentation'),
      snippetContent = generateSnippetMenuList(editorOption),
      blueprintContent = generateBlueprintMenuList(editorOption),
      snippetContentTitle = $('<span/>').addClass('expand-snippets').text("Snippets"),
      blueprintContentTitle = $('<span/>').addClass('expand-blueprints').text("Templates");

    liSnippetParent.append(snippetContentTitle);
    liSnippetParent.append(snippetContent);

    liBlueprintParent.append(blueprintContentTitle);
    liBlueprintParent.append(blueprintContent);

    ul.append(liSnippetParent);
    ul.append(liBlueprintParent);

    return ul[0].outerHTML;
  }

  FroalaEditor.RegisterCommand('textTemplate', {
    title: 'Snippet',
    type: 'dropdown',
    focus: true,
    undo: true,
    html: function () {
      return _generateDropdownHtml(this.opts);
    },
    callback: function (cmd, val, type) {
      if (val == 'create-snippet') {
        this.textTemplate.createNewTemplate(val);
      } else {
        (type === 'blueprint')
          ? this.textTemplate.appendBlueprint(val)
          : this.textTemplate.appendTemplate(val);
      }
      this.textTemplate.resetState();
    },
    plugin: 'textTemplate'
  });

})(window.jQuery, window._, FroalaEditor);